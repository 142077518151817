import React from "react";

import Button from "../button";

const JoinTheTrustNetwork = ({ setShowContactModal }) => {
  return (
    <div className="bg-foreground  overflow-hidden">
      <div className="gradient3 pt-12 md:pb-16 lg:pt-24 flex flex-col items-center">
        <h1 className="header1 opacity-75 slideIn text-white">
          Join The Trudenty Trust Network
        </h1>
        <p className="subtitle1 text-center mt-4 text-white">
          Become a node. Share data. Learn from network intelligence.
        </p>
        <div className="flex gap-x-2 mx-auto mt-8 lg:mt-10 mb-8 lg:mb-16">
          <Button
            hero
            title="Contact us"
            color="secondary"
            onClick={() => setShowContactModal(true)}
          />
        </div>
        <div className="aspect-ratio-box container mx-auto md:min-h-[607px]">
          <iframe
            src="https://www.youtube.com/embed/6twIaEQetDo?si=FQzQ2Vb8QBl-rV_Q"
            width="640"
            height="480"
            allow="loop;"
            allowFullScreen
            title="Trudenty Trust Network"
            className="lg:rounded-2xl md:absolute top-0 left-0 w-full md:h-full"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default JoinTheTrustNetwork;
