import React from "react";
import Lottie from "lottie-react";

// Assets
import PaymentAnimations from "../../../assets/animations/payment_personalisation_illustration.json";

const CheckoutAndPayment = ({ items }) => {
  return (
    <div className="bg-foreground">
      <div className="bg-grey container-fluid">
        <div className="container pt-10 lg:pt-24 pb-18 lg:pb-28 flex flex-1 flex-col">
          <div className="grid lg:grid-cols-2 gap-x-5 w-full gap-y-16 items-center justify-center">
            <div className="order-2 lg:order-1 h-fit max-w-[441px] mx-auto lg:mx-0">
              <Lottie animationData={PaymentAnimations} loop={true} />
            </div>
            <div className="order-1 lg:order-2 lg:ml-20 max-h-fit">
              <h2 className="header4 text-body-black">Personalise payments</h2>
              <p className="subtitle2 mt-2">
                Offer personalised payment experiences to shoppers, at checkout
                and during refunds, based on fraud risk
              </p>
              <div className="flex flex-col gap-y-4 lg:gap-y-10 mt-6 lg:mt-10">
                {items.map((item) => (
                  <div className="flex gap-x-4 lg:gap-x-6" key={item}>
                    <div className="min-w-[14px] w-[14px] min-h-[14px] mt-1 h-[14px] lg:min-w-[18px] lg:w-[18px] lg:min-h-[18px] lg:h-[18px] bg-gray-20/[0.97] rounded-full" />
                    <p className="subtitle2 text-body-black">{item}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutAndPayment;
