import React from "react";

import IntelligenceConsensusIllustration from "../../../assets/svgs/return_and_refund.svg";

const CheckingAndRefundManager = ({ items }) => {
  return (
    <div className="bg-foreground">
      <div className="bg-grey container-fluid">
        <div className="container pt-10 md:pt-16 lg:pt-20 pb-8 md:pb-8 lg:pb-16 lg:flex flex-1">
          <div className="">
            <h2 className="header4 text-body-black">
              Automated and assisted refund decisioning
            </h2>
            <p className="subtitle2 mt-2 max-w-[521px]">
              Enable instant refunds for trusted shoppers, reduce manual work to
              process returns and stop fraudsters
            </p>

            <div className="mt-6 lg:mt-0">
              <div className="lg:pt-10 flex flex-col gap-y-4 lg:gap-y-10">
                {items.map((item) => (
                  <div className="flex gap-x-4 lg:gap-x-6" key={item}>
                    <div className="min-w-[14px] w-[14px] min-h-[14px] h-[14px] lg:min-w-[18px] mt-1 lg:w-[18px] lg:min-h-[18px] lg:h-[18px] bg-gray-20/[0.97] rounded-full" />
                    <p className="subtitle2 text-body-black">{item}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <IntelligenceConsensusIllustration className="max-w-[678px] h-full mx-auto lg:mr-0 w-full mt-10 lg:mt-0" />
        </div>
      </div>
    </div>
  );
};

export default CheckingAndRefundManager;
