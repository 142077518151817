import React from "react";
import { StaticImage } from "gatsby-plugin-image";

// Components
import Button from "../button";

const Hero = ({ setShowContactModal }) => {

  return (
    <>
      <div
        className="container-fluid hero hero1_gradient  flex-1 flex flex-col items-center pb-24"
        id="hero"
      >
        <div className="container pt-20 lg:pt-24 flex flex-1 flex-col items-center justify-center">
          <h1 className="header1 text-black/[0.96] slideOut">
            <span className="products_hero_know ">
              Embed consumer fraud risk <br className="hidden md:block" />{" "}
              intelligence
            </span>{" "}
            and personalise <br className="hidden md:block" /> payments
          </h1>
          <p className="subtitle1 text-center pt-8 lg:pt-10 mb-16">
            The Trudenty Trust Network is a machine-learning powered blockchain
            network
            <br className="hidden md:block" /> that helps merchants ingest
            consumer intelligence from across the commerce{" "}
            <br className="hidden md:block" /> ecosystem, to prevent friendly
            fraud and enable payment personalisation.
          </p>
          <div className="flex gap-x-2">
            <Button
              hero
              title="Contact us"
              onClick={() => setShowContactModal(true)}
            />
          </div>
        </div>
      </div>
      <div className="container hero product_hero_img_mas flex items-center justify-center mx-auto">
        <StaticImage
          src="../../assets/images/website-hero-image.png"
          className="flex-1 h-auto mx-auto"
          alt="hero"
          formats={["AUTO", "PNG"]}
          pngOptions={{ quality: 100 }}
        />
      </div>
    </>
  );
};

export default Hero;
