import React, { useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

// Components
import Button from "./button";

// Assets
import CloseIcon from "../assets/svgs/icons/close_icon.svg";
import Toggle from "./Toggle";

const UnsubscribeModal = ({
  showUnsubscribeModal,
  setShowUnsubscribeModal,
  location,
}) => {
  const [enabled, setEnabled] = useState(true);

  const [id, setId] = useState();
  // const {} = useForm();
  useEffect(() => {
    if (location?.search?.includes("unsubscribe")) {
      setShowUnsubscribeModal(true);
      const params = Object.fromEntries(
        new URLSearchParams(window.location.search)
      );
      setId(params?.unsubscribe);
    }
  }, [location, setShowUnsubscribeModal]);

  const onSubmit = () => {
    if(id) {
       axios
      .delete(`${process.env.REACT_APP_BACKEND_API_URL}/waitlist/member`, { data: { id }})
      .then(function (response) {
        toast.success(
         "You will no longer receive newsletter updates."
        );
        setShowUnsubscribeModal(false)
      })
      .catch(function (error) {
        toast.error(error?.message);
      });
    }

  }

  return (
    <>
      <Transition appear show={showUnsubscribeModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed top-12 inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[375px] py-5 transform overflow-hidden md:rounded-2xl bg-white text-left align-middle shadow-[0px_4px_10px_rgba(0,0,0,0.05)] transition-all">
                  <Dialog.Title
                    as="p"
                    className="flex items-center justify-between mx-4 md:mx-6 rounded-t-md text-black"
                  >
                    <span className="">Email preferences</span>
                    <CloseIcon onClick={() => setShowUnsubscribeModal(false)} />
                  </Dialog.Title>

                  <div className="p-2 md:px-6 w-full">
                    <p className="">
                      You will no longer receive newsletter updates.
                    </p>
                    <div className="mt-2 border border-stroke p-4 rounded flex items-center justify-between">
                      <span className="text-placeholder-grey">Newsletter</span>
                      <Toggle
                        checked={enabled}
                        toggleCheck={() => setEnabled(enabled)}
                      />
                    </div>
                    <Button
                      title="Unsubscribe"
                      category="1"
                      onClick={onSubmit}
                      type="submit"
                      className={`mt-10 w-full`}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UnsubscribeModal;
