import React from "react";

// Components
import CheckingAndRefundManager from "./CheckingAndRefundManager";
import CheckoutAndPayment from "./ProofOfDelivery";
import OnboardingAndAuthentication from "./OnboardingAndAuthentication";

const ValueProposition = () => {
  const listItems1 = [
    "Complete, 360 visibility of the consumer  based on data from multiple data sources",
    "Customise ML algorithms to assess fraud risk in your business context",
    "Predict and detect consumer behaviors and fraud patterns",
  ];
  const listItems2 = [
    "Complete, 360 visibility of the consumer  based on data from multiple data sources",
    "Customise fraud risk algorithms, segment shoppers and define shopper specific experiences to maximise experience whilst limiting fraud",
    "Integrates with any payment gateway or orchestrator",
  ];
  const listItems3 = [
    "Differentiate trusted shoppers from high risk shoppers",
    "Search the Network for information to assist manual refund decision making",
    "Assisted follow up on returns from high risk shoppers to prevent fraud",
  ];
  return (
    <div>
      <OnboardingAndAuthentication items={listItems1} />
      <CheckoutAndPayment items={listItems2} />
      <CheckingAndRefundManager items={listItems3} />
    </div>
  );
};

export default ValueProposition;
