import React, { useState } from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";

// Components
import BuildingAfricaDigitalIdentity from "../components/business-sections/JoinTheTrustNetwork";
import Hero from "../components/business-sections/Hero";
import KYCComplianceAndGlobalLaws from "../components/business-sections/easy-navigation";
import UnsubscribeModal from "../components/UnsubscribeModal";

const BusinessPage = ({ location }) => {
  const [showContactModal, setShowContactModal] = useState(false);
  const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);

  return (
    <Layout {...{ location, showContactModal, setShowContactModal, showUnsubscribeModal, setShowUnsubscribeModal }}>
      <Hero {...{setShowContactModal }} />
      <KYCComplianceAndGlobalLaws />
      <BuildingAfricaDigitalIdentity {...{setShowContactModal}} />
      <UnsubscribeModal {...{showUnsubscribeModal, setShowUnsubscribeModal, location}}/>
    </Layout>
  );
};

export const Head = () => <Seo title="Trudenty" />;

export default BusinessPage;
