import React from "react";
// import { StaticImage } from "gatsby-plugin-image";

// Assets
import inel from "../../../assets/videos/consumer-intelligence-illustration.mp4";

const OnboardingAndAuthentication = ({ items }) => {
  return (
    <>
      <div
        className="bg-foreground hero scroll-m-20 mt-20 lg:mt-0"
        id="use-cases"
      >
        <div className="bg-grey container-fluid hero">
          <div className="container">
            <div className="product-title header7">Use cases</div>
          </div>
          <div className="container h-full pt-8 lg:pt-12 pb-8 md:pb-8 lg:pb-24 grid lg:grid-cols-2 gap-x-10">
            <div className="">
              <h2 className="header4 text-body-black">
                Embed consumer fraud risk intelligence
              </h2>
              <p className="subtitle1 mt-2">
                Differentiate trusted shoppers from high risk shoppers in real
                time, to prevent friendly fraud during checkout and returns
              </p>

              <div className="mt-6 lg:mt-10 gap-x-1.25 w-full gap-y-4 max-w-[403px]">
                <div className="flex flex-col gap-y-4 lg:gap-y-10">
                  {items.map((item) => (
                    <div className="flex gap-x-4 lg:gap-x-6" key={item}>
                      <div className="min-w-[14px] w-[14px] min-h-[14px] h-[14px] lg:min-w-[18px] lg:w-[18px] lg:min-h-[18px] lg:h-[18px] mt-1 bg-gray-20/[0.97] rounded-full" />
                      <p className="subtitle2 text-body-black">{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <video
              autoPlay
              loop
              muted
              playsInline
              poster="../../../assets/images/consumer-intelligence-illustration.png"
              src={inel}
              className=" rounded-3xl max-w-[90%] md:max-w-[508px] mx-auto lg:ml-auto mt-16 lg:mt-0"
            >
            </video>
            {/* <div className="lg:hidden flex flex-col justify-center">
              <StaticImage
                alt="shopper trust index"
                formats={["AUTO", "PNG"]}
                pngOptions={{ quality: 100 }}
                src="../../../assets/images/consumer-intelligence-illustration.png"
                className="lg:hidden rounded-3xl max-w-[90%] md:max-w-[508px] mx-auto min-h-[100vw] max-h-[500px] mt-16"
              />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingAndAuthentication;
